<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12" v-if="!field.group_key">
          <field-filled-by :field="field" />
        </el-col>
        <el-col :span="8" v-if="!field.group_key">
          <is-field-required :field="field" class="field-required"/>
        </el-col>
        <el-col :span="8" class="mr-2" v-if="!field.group_key">
          <label>Custom Error Notification</label>
          <el-input
            type="textarea"
            :rows="3"
            placeholder="Please enter a custom notification message."
            v-model="field.default_error_message"
          >
          </el-input>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>


<script>
// import TitleAndDescription from "./TitleAndDescription";
// import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
import { mapGetters } from "vuex";
export default {
  name: "templates-formComponents-Checkbox",
  components: {
    "TitleAndDescription":()=>import("./TitleAndDescription"),
    "IsFieldRequired":()=>import( "./IsFieldRequired"),
    "FieldFilledBy":()=>import("./FieldFilledBy"),
  },
  props: ["field"],
  data() {
    return {
      validations: [],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1"
        },
        {
          title: "Folder 2",
          _id: "2"
        },
        {
          title: "Folder 3",
          _id: "3"
        }
      ]
    };
  },
  mounted() {
    this.fetchGlobalVaribales();
  },
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },
  methods : {
    async fetchGlobalVaribales() {
      let params = {
        get_all: true,
        input_type: this.field.input_type,
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        (x) => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
    },
  }
};
</script>

<style lang="scss">
</style>