<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" />
        </el-col>
      </el-row>
      <div class="mt-2">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="Upload Type:">
              <div class="d-flex mt-10">
                <el-radio v-model="field.validations.multiple" :label="false"
                  >Single</el-radio
                >
                <el-radio v-model="field.validations.multiple" :label="true"
                  >Multiple</el-radio
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div v-if="field.validations.multiple" style="margin-bottom: 2px">
              <el-row :gutter="12">
                <el-col :span="6">
                  <div class="form-group">
                    <el-form-item label="Min">
                      <el-input
                        type="number"
                        min="1"
                        v-model="field.validations.min_document_files"
                        placeholder="Minium Files To upload"
                      ></el-input>
                      <!-- <FormError error-key="Min" /> -->
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="form-group">
                    <el-form-item label="Max">
                      <el-input
                        type="number"
                        min="1"
                        v-model="field.validations.max_document_files"
                        placeholder="Maximum Files To upload"
                      ></el-input>
                      <!-- <FormError error-key="Min" /> -->
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Accepted File`s">
              <el-select
                v-model="field.validations.allowed_file_types"
                multiple
                filterable
                default-first-option
                :collapse-tags="true"
                placeholder="Select File types"
              >
                <el-option
                  v-for="item in fileTypes"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="field.validations.multiple && isExecute">
            <el-upload
              action
              :on-change="upload"
              multiple
              :on-remove="removeFile"
              class="avatar-uploader"
              :show-file-list="true"
              :auto-upload="false"
            >
              <!-- {{!form[data.key]}} -->
              <img
                v-if="field && !field.files"
                src="@/assets/img/icons/upload-icon.svg"
                alt="icon"
              />
              <span v-else> {{ fileName }} </span>
            </el-upload>
          </el-col>
          <el-col
            :span="8"
            v-else-if="
              !field.validations.multiple &&
              (field.filled_by == 'SENDER' ||
                field.filled_by == 'SENDER_OR_RECEIVER') &&
              isExecute
            "
          >
            <el-upload
              :on-remove="removeFile"
              action
              :on-change="upload"
              class="avatar-uploader"
              :show-file-list="false"
              :auto-upload="false"
            >
              {{
                field && field.files && field.files[0] && field.files[0].name
                  ? field.files[0].name
                  : ""
              }}
              <!-- {{!form[data.key]}} -->
              <img
                v-if="field && !field.files"
                src="@/assets/img/icons/upload-icon.svg"
                alt="icon"
              />
              <!-- <span v-else> {{ fileName}} </span> -->
            </el-upload>
          </el-col>
        </el-row>
      </div>

      <!-- Need to add folder name below here -->
      <!-- <el-row :gutter="50">
                <el-col :span="12">
                    <div class="form-group">
                        <el-form-item label="Folder Group">
                            <el-select v-model="field.folder_group" placeholder="Select Folder Group">
                                <el-option
                                    v-for="item in rowsOptions"
                                    :key="item._id"
                                    :label="item.title"
                                    :value="item._id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </el-col>
      </el-row>-->

      <!-- <el-row :gutter="50">
        <el-col :span="12">
          <div class="form-group">
            Is it E-Sign Needed?
            <el-radio v-model="field.e_sign_required" label="1">Yes</el-radio>
            <el-radio v-model="field.e_sign_required" label="2">No</el-radio>
          </div>
        </el-col>
      </el-row>-->
    </el-form>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
// import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
import { bus } from "../../../main";
export default {
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
  },
  props: ["field", "isExecute"],
  data() {
    return {
      validations: [],
      files: [],
      fileName: "",
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],
      fileTypes: [
        {
          value: "image/gif",
          name: "Gif",
        },
        {
          value: "image/jpeg",
          name: "JPEG",
        },
        {
          value: "image/svg+xml",
          name: "SVG",
        },
        {
          value: "image/png",
          name: "PNG",
        },
        {
          value: "application/vnd.ms-powerpoint",
          name: "PPT",
        },
        {
          value: "application/vnd.ms-excel",
          name: "Xls",
        },
        {
          value: "application/pdf",
          name: "pdf",
        },
        {
          value: "application/msword",
          name: "Doc",
        },
        {
          value:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          name: "DocX",
        },
        {
          value:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          name: "XlsX",
        },
        {
          value: "text/csv",
          name: "CSV",
        },
      ],
    };
  },
  async mounted() {
    this.files =
      this.field && this.field.files && this.field.files.length
        ? this.field.files
        : [];
    //this.field.files = null
    this.fileName =
      this.files && this.files[0] && this.files[0].name
        ? this.files[0].name
        : "";
  },
  methods: {
    async upload(file) {
      this.files = [];
      this.fileName = file.name;
      // this.fileName = file.name;
      // if (this.data.validations.multiple) {
      this.files.push(file);
      // }
      bus.$emit("fileUploading", this.files);
      this.$set(this.field, "files", this.files);
    },
    removeFile(file) {
      const index = this.files.indexOf(file);
      // Remove the file from the array using the splice method
      if (index > -1) {
        this.files.splice(index, 1);
      }
    },
    linkSubmit() {
      this.loading = true;
      this.field.files = this.files;
      this.loading = false;
    },
  },
  async default() {
    this.field.validations.multiple = false;
  },
};
</script>

<style lang="scss"></style>
